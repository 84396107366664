import { memo, useEffect, useMemo, useRef, useState } from 'react'
import { defaultPalette } from 'const/palette'
import useThrottledMemo from 'hooks/useMemoThrottle'
import { throttle } from 'lodash'
import findIndex from 'lodash/findIndex'
import dynamic from 'next/dynamic'

// Function to trim punctuation from the ends of words
export const cleanWord = (word = '') => {
  const punctuation = /[!"#$%&'*+,-./:;<=>?@[\]^_`{|}~]$/g
  return word.trim().toLowerCase().replace(punctuation, '')
}

const ReactWordcloud = dynamic(() => import('react-wordcloud'), {
  ssr: false,
})

const colors = {}

function TagCloud({ tags = [], theme, maxTags = 100 }) {
  const palette = theme?.palette || defaultPalette
  const [size, setSize] = useState()
  const containerRef = useRef()
  const data = useThrottledMemo(
    () =>
      tags
        .flat()
        .map((item) => cleanWord(item))
        .reduce((acc, tag) => {
          const index = findIndex(acc, { text: tag })
          if (index > -1) {
            acc[index].value += 1
          } else {
            acc.push({ text: tag, value: 1 })
          }
          return acc
        }, []),
    1000,
    [tags, maxTags],
  )

  const options = useMemo(
    () => ({
      rotations: 0,
      rotationAngles: [-90, 0],
      fontFamily: theme?.font?.family || 'sans-serif',
      fontSizes: [32, 96],
      enableTooltip: false,
      enableOptimizations: true,
      deterministic: true,
    }),
    [theme],
  )

  const callbacks = useMemo(
    () => ({
      getWordColor: ({ text }) => {
        if (colors[text]) return colors[text]
        const totalColors = Object.keys(colors).length
        const paletteIndex = totalColors % palette.length
        const color = palette[paletteIndex]
        colors[text] = color
        return color
      },
    }),
    [],
  )

  useEffect(() => {
    const handleResize = throttle(() => {
      if (containerRef.current) {
        const { width, height } = containerRef.current.getBoundingClientRect()
        setSize([width, height])
      }
    }, 100)

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  useEffect(() => {
    if (containerRef.current) {
      const { width, height } = containerRef.current.getBoundingClientRect()
      setSize([width, height])
    }
  }, [])

  return (
    <div className="h-full w-full" ref={containerRef}>
      {size && (
        <ReactWordcloud
          words={data}
          options={options}
          size={size}
          callbacks={callbacks}
        />
      )}
    </div>
  )
}

export default memo(TagCloud)
